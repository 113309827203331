.btn-scrol-page-top{
    position: fixed;
    bottom: 5rem;
    right: 2rem;
    font-size: 5vh;
    background:#0d6efd;
    color: white;
    cursor: pointer;

    border: none;
    box-shadow: 0 5px 10px #ccc;
}
.btn-scrol-page-top:hover {
    background: #0a58ca;
  }